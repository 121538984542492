/**
 * Created by Asad on 7/21/2019.
 */
export const Paths = {
    api: {
        change_password: 'auth/change-password',
    },
    admin: {
        update: 'admin/update',
    },
    salesman: {
        index: 'salesman/index',
        add: 'salesman/store',
        update: 'salesman/update',
        update_profile: 'salesman/update-profile',
    },
    deliveryperson: {
        index: 'deliveryperosn/index',
        add: 'deliveryperosn/store',
        update: 'deliveryperosn/update',
        update_profile: 'deliveryperosn/update-profile',
    },
    unit: {
        index: 'unit/index',
        add: 'unit/add',
        update: 'unit/update',
    },
    customer: {
        index: 'customer/index',
        add: 'customer/store',
        update: 'customer/update',
        detail: 'customer/detail',
        update_profile: 'customer/update-profile',
        register: 'customer/register',
        requests: 'customer/requests',
        filters: 'customer/filters',
        max_payment_limit: 'customer/max_payment_limit',
        payment_interval: 'customer/payment_interval',
        search: 'customer/search',
        balance: 'customer/balance',
        last_order: 'customer/last_order',
    },
    category: {
        index: 'category/index',
        add: 'category/store',
        update: 'category/update',
    },
    inventory: {
        table: '/inventory/table',
        famous: '/inventory/famous',
        discount: '/inventory/discount',


    },
    waste: {
        store: 'waste'
    },
    transaction: {
        store: 'transaction/store',
        delete: 'transaction/delete',
        for_inventory: 'transaction/for-inventory',
    },
    state: {
        index: 'state/index',
        validate_zip: 'state/validate/zip',
        search: 'state/search',
    },
    city: {
        search: 'city/search',
    },

    zip_code: {
        search: 'zip/search',
    },
    item: {
        all: 'items/all',
        with_inventory: 'items/with_inventory',
        detail: '/items/get_with_inventory',
        some: 'items/some',
        search_by_name: 'items/search_by_name',
        store: 'items/store',
        update: 'items/update',
        drop_down: 'items/drop_down',
        suggestion: 'items/query',
        load: 'items/load',
        for_order: 'items/for_order',
        customer: {
            last_purchase: 'items/last/purchases/customer',
        }
    },
    user: {
        status: 'user/status',
        suggestion: 'user/suggestion',
    }
    ,
    data: {
        fetch: 'data/fetch',
    }
    ,
    shop: {
        available: 'shop/available',
    }
    ,
    favourite: {
        do: 'favourite/do',
        cancel: 'favourite/cancel',
    }
    ,
    order: {
        send: 'order/send',
        save: 'order/save',
        update: 'order/update',
        cancel: 'order/cancel',
        customer_orders: 'order/customer-orders',
        new: 'order/new',
        assigned: 'order/assigned',
        approved: 'order/approved',
        rejected: 'order/rejected',
        completed: 'order/completed',
        approve: 'order/approve',
        reject: 'order/reject',
        assign: 'order/assign',
        cancel_delivery: 'order/cancel_delivery',
        complete: 'order/complete',
        pdf: 'order/download_pdf',
        delivery_person: {
            assigned: 'order/delivery-person/assigned',
            completed: 'order/delivery-person/completed'
        }
        ,
        salesman: {
            assign: 'order/salesman/assign',
        },
        warehouse: {
            assign: 'order_warehouse',
        }
        ,
        all: 'order/all',
        search: {
            date: 'order/search/date',
        },
        get: 'order/get',
        preparation: {
            update: 'order_preparation',
        },
        with_payment: 'order/with_payment',
        payment_due_for_customer: 'order/payment_due_for_customer',
        save_payment: 'order/save_payment',
    }
    ,
    registrations: {
        reject: 'registration/reject',
        approve: 'registration/approve',
        assign: 'registration/assign',
        new: 'registration/new',
        get: 'registration/get',
    },
    notifications: {
        all: 'notifications/all',
        read: 'notifications/read',
    },
    billingAddress: {
        store: 'billing/store',
        index: 'billing/index',
    },
    deliveryAddress: {
        store: 'delivery_address/store',
        index: 'delivery_address/index',
    },
    message: {
        send: 'message/send',
        read: 'message/read',
        all: 'message/all',
        create: 'message/create',
        mark: 'message/mark',
    },
    subcategory: {
        index: 'sub_category/index',
        create: 'sub_category/create',
        update: 'sub_category/update',
    },
    routes: {
        index: 'route',
        store: 'route',
        update: 'route/0'
    },
    warehouse_staff: {
        index: 'warehouseperson',
        store: 'warehouseperson',
        update: 'warehouseperson/0',
    },
    brand: {
        index: 'brand/index',
        save: 'brand/save',
    },

    charts: {
        line: {
            daily: 'chart/line/daily',
        }
    }


}