<template>
    <div id="index-container" style="">
        <SlideShow></SlideShow>
        <AboutUs></AboutUs>
        <Quote></Quote>
        <Footer></Footer>
        <!--<div class="bg-banner main-banner pt-5">-->

        <!--<div class="main-box-container">-->
        <!--<div class="row bottom-0 h-100">-->
        <!--<div class="col-sm-12">-->


        <!--</div>-->

        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<section class="bg-light pb-5">-->
        <!--<div class="container">-->
        <!--<product-categories></product-categories>-->
        <!--</div>-->
        <!--</section>-->
        <!--<section class="bg-green pb-5">-->
        <!--<div class="container">-->
        <!--<div class="row">-->
        <!--<div class="col-sm-10 col-md-8 col-lg-8 mx-auto">-->
        <!--<h1 class="text-center font-weight-bold mb-5 text-light">Our Food Suppliers</h1>-->
        <!--</div>-->
        <!--<div class="col-sm-10 col-md-8 col-lg-8 mx-auto">-->
        <!--<div class="row">-->
        <!--<div class="col-sm-4 col-md-3 mb-3 ">-->
        <!--<div class="card shadow">-->


        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/kemps-logo.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;Kemps&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="col-sm-4 col-md-3 mb-3">-->
        <!--<div class="card shadow">-->


        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/Sysco.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;SYSCO&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="col-sm-4 col-md-3 mb-3">-->
        <!--<div class="card shadow">-->


        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/costco.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;Costco&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="col-sm-4 col-md-3 mb-3">-->
        <!--<div class="card shadow">-->
        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/landolakes.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;Land O'Lakes&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="col-sm-4 col-md-3 mb-3">-->
        <!--<div class="card shadow">-->


        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/Sam's-Club.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;Sam's Club&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="col-sm-4 col-md-3 mb-3">-->
        <!--<div class="card shadow">-->
        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/maxresdefault.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;General Mills&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="col-sm-4 col-md-3 mb-3">-->
        <!--<div class="card shadow">-->


        <!--<div class="card-body border-top pt-1 pb-1">-->
        <!--<img class="card-img-top "-->
        <!--src="../../assets/company_logos/Restaurant_Depot.png"-->
        <!--alt="Card image cap" @load="secureLoaded">-->
        <!--&lt;!&ndash;<h4 class="card-title text-center  bold">&ndash;&gt;-->
        <!--&lt;!&ndash;Restaurant Depot&ndash;&gt;-->
        <!--&lt;!&ndash;</h4>&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</section>-->


        <!--<section>-->
        <!--<div class="container text-left">-->
        <!--<div class="row">-->
        <!--<div class="col-sm-12 pl-0 pr-0">-->
        <!--<p class="text-left text-success text-3x">Contact Details </p>-->
        <!--<hr style="background-color: white;">-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<div class="col-sm-6 mb-1">-->
        <!--<div class="row pr-sm-2">-->
        <!--<div class="col-sm-12 shadow p-4">-->
        <!--<p class="heading">Working Hours</p>-->
        <!--<hr>-->

        <!--<p>Monday - Friday 8:00 am - 6:30 pm <br>-->
        <!--(Phone until 8:30 pm)<br>-->
        <!--</p><br>-->
        <!--<br/>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="col-sm-6 mb-1">-->
        <!--<div class="row pl-sm-2">-->
        <!--<div class="col-sm-12 shadow p-4">-->
        <!--<p class="heading">WE ARE HERE</p>-->
        <!--<hr>-->
        <!--<p>2904 N 2nd Ave <br> Minneapolis MN 55411.-->
        <!--<br>-->
        <!--Phone: <a href="tel:+1612-564-6216">+1 612-564-6216</a>-->
        <!--<br>-->

        <!--Email:  info@ushalalfoodinc.com </p>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</section>-->
    </div>
</template>

<script type="text/javascript">
    import Shop from '@/classes/InventoryHandler';
    //    import Login from '@/views/forms/Login';
    import SlideShow from '@/views/index/SlideShow.vue';
    import AboutUs from '@/views/index/AboutUs.vue';
    import Quote from '@/views/index/Quote.vue';
    import Footer from '@/views/index/Footer.vue';

    //    import ProductCategories from '@/views/index/ProductCategories';
    export default{
        components: {SlideShow, AboutUs, Quote, Footer},
        data()
        {
            return {
                items: [],
                category_max_height: 0,
                random_heights: {
                    min_height: 0,
                    max_height: 0,
                    height: 0,

                },
                second_section: {
                    marginTop: "50px",
                },
                container_class: 'col-sm-11 col-lg-9 mx-auto bg-light ',
                category_height: {
                    height: 'auto',
                    width: '100%',
                }
            }
        }
        ,
        methods: {
            secureLoaded()
            {
//                let height = this.$refs['secure'].clientHeight;
//                this.random_heights.min_height = height + 'px';
//                this.random_heights.height = height + 'px';
//                this.random_heights.max_height = height + 'px';
//
//                if (screen.width < 600) {
//                    console.log('Small screen');
//                    this.second_section.marginTop = (height * 1.1 ) + 'px';
//                    return;
//                }
//                this.second_section.marginTop = (height ) + 'px';


            },
            calculateCategoryHeight(event){
                if (event.target.clientHeight > this.category_max_height) {
                    this.category_max_height = event.target.clientHeight;
                    this.category_height.height = this.category_max_height + 'px';
                }

            },

            handleResize()
            {

                this.secureLoaded();
//                setGallery();
            },
            famousOnly()
            {
                return this._.filter(this.items, function (item) {
                    return item.is_famous == 1;
                })
            },

        },
        computed: {
            random()
            {
                let items = this.famousOnly();
                if (items.length < 4) {
                    items = this.items;
                }

                return this._.sampleSize(items, 4);
            },

            categories(){
                let categories = {};

                let app = this;
                this.items.forEach(function (item) {

                    if (categories[item.item.category_id] == undefined) {

                        categories[item.item.category_id] = {
                            name: app.$store.getters.categories[item.item.category_id].name,
                            image: item.item.image,
                            id: item.item.category_id,
                        };
                    }
                });
                return categories;
            },
            famousCategories(){
                let categories = {};
                let app = this;
                let items = this.famousOnly();
                items.forEach(function (item) {
                    if (categories[item.item.category_id] == undefined) {
                        categories[item.item.category_id] = {
                            category: app.$store.getters.categories[item.item.category_id],
                            items: [],
                        }
                    }

                    if (categories[item.item.category_id].items.length < 5) {
                        categories[item.item.category_id].items.push(item.item);
                    }


                });

                return categories;
            }
        }
        ,
        mounted()
        {
//            if (this.$auth.check()) {
//                this.$router.push({'name': 'dashboard'});
//            }
            let app = this;

            Shop.load().then((response) => {
                app.items = response.data;
            })

            window.addEventListener('resize', this.handleResize);

            window.dispatchEvent(new Event('gallery'));
        }
        ,
        ready: function () {

        }
        ,
        beforeDestroy: function () {
            window.removeEventListener('resize', this.handleResize)

        }
        ,

    }
</script>
<style type="text/css">
    .main-box-container {
        /*position: absolute;*/
        /*bottom: -50px;*/
        height: 100%;
        /*margin-top: 300px;*/
    }

    .banner-btn {
        font-weight: bolder;
        color: white;
        background-color: #ff7f41ff;
        font-size: 1.3em;
        text-decoration: none;
        padding: 20px 30px;
        width: 100%;
        position: relative;
        float: left;
    }

    .banner-btn:hover {
        font-weight: bolder;
        color: white;
        background-color: transparent;
        border: 3px solid white;
        font-size: 1.3em;
        text-decoration: none;
        padding: 20px 30px;
        width: 100%;
        position: relative;
        float: left;
    }

    .op-bg {
        background: url("../../assets/image/op-bg.png") repeat;
    }



    .bottom-0 {
        bottom: 0px !important;
    }

    .main-banner {
        height: 600px;
    }

    @media (max-width: 576px) {
        .main-banner {
            height: 550px;
        }
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 992px) {
        .main-box-container {
            /*margin-top: 300px;*/
        }
    }

    @media (min-width: 1200px) {
    }

</style>
