import Vue from "vue";
import Router from "vue-router";
import Index from "../views/index/Index.vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
Vue.use(Router);
// axios.defaults.baseURL = 'http://127.0.0.1:1234/api';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
if (window.location.host.indexOf('localhost') > -1) {
    // axios.defaults.baseURL = 'http://127.0.0.1:1234/api';
    axios.defaults.baseURL = 'https://ushalal-services.dms-cacfpsfsp.com/api';
} else {
    // axios.defaults.baseURL = 'https://services.ushalalfoodinc.com/api';
    axios.defaults.baseURL = 'https://ushalal-services.dms-cacfpsfsp.com/api';
}


export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index
        },
        {
            path:'/who-we-are',
            name:'who_we_are',
            component:()=>import('../views/index/WhoWeAre.vue')
        },
        {
            path:'/who-we-serve',
            name:'who_we_serve',
            component:()=>import('../views/index/WhoWeServe.vue')
        },
        {
            path:'/offers-for-business',
            name:'offers_for_business',
            component:()=>import('../views/index/OffersForBusiness.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/forms/LoginContainer.vue'),

        },
        {
            path: '/customer/request',
            name: 'customer.request',
            component: () => import('../views/forms/RegisterRequest.vue'),

        },
        {
            path: '/customer-requests',
            name: 'customer.requests',
            component: () => import('../views/registrations/Index.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }

        },
        {
            path: '/customer-requests/:id',
            name: 'customer.request.detail',
            component: () => import('../views/registrations/DetailPage.vue'),
            props: true,
            meta: {
                auth: {roles: ['admin', 'sales', 'customer'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/sales/customer-requests',
            name: 'sales.customer.requests',
            component: () => import('../views/registrations/sales/Index.vue'),
            meta: {
                auth: {roles: ['sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }

        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../views/account/Dashboard.vue'),
            meta: {
                auth: true,
            }
        },
        {
            path: '/salesman',
            name: 'salesman',
            component: () => import('../views/salesman/Index.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/warehouse_staff',
            name: 'warehousePerson',
            component: () => import('../views/warehousePerson/Index.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },

        {
            path: '/deliveryperson',
            name: 'deliveryperson',
            component: () => import('../views/deliveryperson/Index.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        }, {
            path: '/customer',
            name: 'customer',
            component: () => import('../views/customer/Index.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },{
            path: '/customer/add',
            name: 'customer.add',
            component: () => import('../views/customer/Add.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        }
        ,{
            path: '/customer/:user_id/detail',
            name: 'customer.detail',
            props:true,
            component: () => import('../views/customer/Detail.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        }
        ,
        {
            path: '/categories',
            name: 'category',
            component: () => import('../views/category/Index.vue'),
            meta: {
                auth: {roles: 'admin', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/food-items',
            name: 'items',
            component: () => import('../views/items/Index.vue'),
            meta: {
                auth: {roles: ['admin', 'sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        }
        , {
            path: '/units',
            name: 'units',
            component: () => import('../views/unit/Index.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/brands',
            name: 'brands',
            component: () => import('../views/brand/Index.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/inventory',
            name: 'inventory',
            component: () => import('../views/inventory/Index.vue'),
            meta: {
                auth: {roles: ['admin', 'sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/inventory/:item_id/detail',
            name: 'inventory.detail',
            props:true,
            component: () => import('../views/inventory/Detail.vue'),
            meta: {
                auth: {roles: ['admin', 'sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('../views/account/Info.vue'),
            meta: {
                auth: {
                    roles: ['admin', 'sales', 'delivery', 'customer'],
                    redirect: {name: 'login'},
                    forbiddenRedirect: '/403'
                }
            }
        },
        // {
        //     path: '/profile/sales-man',
        //     name: 'sales.profile',
        //     component: () => import('../views/account/salesman/Info.vue'),
        //     meta: {
        //         auth: {roles: 'sales', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
        //     }
        // },
        {
            path: '/products/:category?/:query?',
            name: 'shop',
            component: () => import('../views/shop/Index.vue'),
            props: true,
            meta: {
                // auth: {roles: 'customer', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/products/:category/:name',
            name: 'shop.detail',
            component: () => import('../views/shop/Detail.vue'),
            props: true,
            meta: {}
        },
        {
            path: '/cart',
            name: 'cart',
            component: () => import('../views/shop/Cart.vue'),
            meta: {
                auth: {roles: 'customer', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        }, {
            path: '/cart/preview',
            name: 'cart.preview',
            component: () => import('../views/shop/PreviewOrder.vue'),
            meta: {
                auth: {roles: 'customer', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/cart/purchased/:order',
            name: 'cart.purchased',
            props: true,
            component: () => import('../views/shop/Purchased.vue'),
            meta: {
                auth: {roles: 'customer', redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/my-orders',
            name: 'customer.orders',
            component: () => import('../views/order/CustomerOrders.vue'),
            meta: {
                auth: {roles: ['customer'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/orders',
            name: 'orders',
            component: () => import('../views/order/Index.vue'),
            meta: {
                auth: {roles: ['sales', 'delivery', 'admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
         {
            path: '/orders/create',
            name: 'orders.create',
            component: () => import('../views/order/Create.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        }, {
            path: '/orders/edit/:order_id',
            name: 'orders.edit',
            props:true,
            component: () => import('../views/order/Edit.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/orders/detail/:order_id',
            name: 'orders.admin.detail',
            props:true,
            component: () => import('../views/order/Detail.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },

        {
            path: '/order/:id/:nid',
            name: 'order.detail',
            component: () => import('../views/order/OrderDetailContainer.vue'),
            props: true,
            meta: {
                auth: {
                    roles: ['sales', 'delivery', 'admin', 'customer'],
                    redirect: {name: 'login'},
                    forbiddenRedirect: '/403'
                }
            }
        },
        {
            path: '/deliveries/new',
            name: 'deliveries.new',
            component: () => import('../views/order/deliveries/NewOrders.vue'),
            meta: {
                auth: {roles: ['sales', 'delivery'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/warehouse/orders',
            name: 'orders.warehouse',
            component: () => import('../views/order/warehouse/Orders.vue'),
            meta: {
                auth: {roles: ['warehouse'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/orders/assigned',
            name: 'orders.assigned',
            component: () => import('../views/order/salesman/AssignedOrders.vue'),
            meta: {
                auth: {roles: ['sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/orders/rejected',
            name: 'orders.rejected',
            component: () => import('../views/order/salesman/RejectedOrders.vue'),
            meta: {
                auth: {roles: ['sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/orders/completed',
            name: 'orders.completed',
            component: () => import('../views/order/salesman/DeliveredOrders.vue'),
            meta: {
                auth: {roles: ['sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/orders/approved',
            name: 'orders.approved',
            component: () => import('../views/order/salesman/ApprovedOrders.vue'),
            meta: {
                auth: {roles: ['sales'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/messages/:id?',
            name: 'messages',
            component: () => import('@/views/message/Messages.vue'),
            props: true,
            meta: {
                auth: {
                    roles: ['sales', 'admin', 'customer', 'delivery'],
                    redirect: {name: 'login'},
                    forbiddenRedirect: '/403'
                }
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import('@/views/reports/Reports.vue'),
            meta: {
                auth: {
                    roles: ['admin'],
                    redirect: {name: 'login'},
                    forbiddenRedirect: '/403'
                }
            }
        },
        {
            path: '/routes',
            name: 'routes',
            component: () => import('@/views/routes/Index.vue'),
            meta: {
                auth: {
                    roles: ['admin'],
                    redirect: {'name': 'login'},
                    forbiddenRedirect: '/403'
                }
            }
        },
        {
            path: '/accounts',
            name: 'accounts',
            component: () => import('@/views/account/admin/Accounts.vue'),
            meta: {
                auth: {
                    roles: ['admin'],
                    redirect: {'name': 'login'},
                    forbiddenRedirect: '/403'
                }
            }
        },


        {
            path: '/test',
            name: 'test',
            component: () => import('@/views/test/Test.vue'),
        },

        {
            path: '/payments',
            name: 'payments',
            component: () => import('../views/payment/Index.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/payment/add',
            name: 'payment.add',
            component: () => import('../views/payment/Create.vue'),
            meta: {
                auth: {roles: ['admin'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
            }
        },


    ],
    // mode: 'hash',
    mode: 'history',
    // hash: true,

})