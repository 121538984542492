<template>


    <header class="top-navbar">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
                <router-link class="navbar-brand" :to="{name:'index'}">
                    <img style="width: 200px; height: 60px" src="images/logo.png" alt=""/>
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars-rs-food"
                        aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbars-rs-food">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active">
                            <router-link class="nav-link" :to="{name:'index'}">Home</router-link>
                        </li>

                        <li class="nav-item" v-if="$auth.check()">
                            <router-link class="nav-link" :to="{name:'dashboard'}">Dashboard
                            </router-link>
                        </li>

                        <!--<div class="container-fluid" v-if="">-->
                        <li class="nav-item" v-if="!$auth.check()">
                            <router-link :to="{name:'customer.request'}" class="nav-link">
                                Register
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="!$auth.check()">
                            <router-link :to="{name:'login'}" class="nav-link">
                                Login
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$auth.check()">
                            <a @click.prevent="$auth.logout()" href="#" class="nav-link">
                                Logout
                            </a>
                        </li>


                        <!--<li class="nav-item"><a class="nav-link" href="#">About</a></li>-->
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{'name':'shop'}" href="#">Products</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdown-a"
                               data-toggle="dropdown">About Us</a>
                            <div class="dropdown-menu" aria-labelledby="dropdown-a">
                                <router-link :to="{name:'who_we_are'}" class="dropdown-item">
                                    Who We Are
                                </router-link>
                                <router-link :to="{name:'who_we_serve'}" class="dropdown-item">
                                    Who We Serve
                                </router-link>
                                <router-link :to="{name:'offers_for_business'}" class="dropdown-item">
                                    Our Offers
                                </router-link>

                            </div>
                        </li>
                        <!--<li class="nav-item dropdown">-->
                        <!--<a class="nav-link dropdown-toggle" href="#" id="dropdown-b"-->
                        <!--data-toggle="dropdown">Supplier</a>-->
                        <!--<div class="dropdown-menu" aria-labelledby="dropdown-a">-->
                        <!--<a class="dropdown-item" href="blog.html">Supplier</a>-->
                        <!--<a class="dropdown-item" href="blog-details.html">Supplier 1</a>-->
                        <!--</div>-->
                        <!--</li>-->
                        <li class="nav-item"><a class="nav-link" href="#">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>

    <!--<nav class="navbar  navbar-expand-lg  navbar-custom  ">-->
    <!--<div class="container-fluid" v-if="$auth.ready()">-->
    <!--<a class="navbar-brand" href="#">-->
    <!--<img src="../../assets/image/ushalal_logo.png" height="40px" alt="">-->
    <!--</a>-->
    <!--<button refs="hamburger" class="navbar-toggler" type="button" data-toggle="collapse"-->
    <!--data-target="#navbarSupportedContent"-->
    <!--aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
    <!--<span class="navbar-toggler-icon">-->
    <!--<icon icon="bars" style="color:#fff; font-size:28px;"></icon>-->
    <!--</span>-->
    <!--</button>-->
    <!--<div class="collapse navbar-collapse" id="navbarSupportedContent">-->
    <!--<ul class="navbar-nav mr-auto" v-if="!$auth.check()">-->
    <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-link class="nav-link" :to="{name:'index'}">Home</router-link>&ndash;&gt;-->
    <!--&lt;!&ndash;</li>&ndash;&gt;-->
    <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-link class="nav-link" :to="{name:'shop'}">Our Products</router-link>&ndash;&gt;-->
    <!--&lt;!&ndash;</li>&ndash;&gt;-->


    <!--</ul>-->
    <!--<ul class="navbar-nav mr-auto" v-if="$auth.check('admin')">-->
    <!--<li class="nav-item" v-for="(route,index) in admin" v-bind:key="index">-->
    <!--<router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>-->
    <!--</li>-->


    <!--</ul>-->
    <!--<ul class="navbar-nav mr-auto" v-else-if="$auth.check('sales')">-->
    <!--<li class="nav-item" v-for="(route,index) in salesman" v-bind:key="index">-->
    <!--<router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>-->
    <!--</li>-->

    <!--</ul>-->
    <!--<ul class="navbar-nav mr-auto" v-else-if="$auth.check('warehouse')">-->
    <!--<li class="nav-item" v-for="(route,index) in warehouse" v-bind:key="index">-->
    <!--<router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>-->
    <!--</li>-->

    <!--</ul>-->
    <!--<ul class="navbar-nav mr-auto" v-else-if=" $auth.check('customer')">-->
    <!--<li class="nav-item">-->
    <!--<router-link class="nav-link" :to="{name:'dashboard'}">Dashboard</router-link>-->
    <!--</li>-->
    <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-link class="nav-link" :to="{name:'customer.orders'}">Orders</router-link>&ndash;&gt;-->
    <!--&lt;!&ndash;</li>&ndash;&gt;-->
    <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-link class="nav-link" :to="{name:'cart'}">Cart</router-link>&ndash;&gt;-->
    <!--&lt;!&ndash;</li>&ndash;&gt;-->
    <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-link class="nav-link" :to="{name:'messages'}">Messages</router-link>&ndash;&gt;-->
    <!--&lt;!&ndash;</li>&ndash;&gt;-->


    <!--</ul>-->
    <!--<ul class="navbar-nav mr-auto" v-else-if="$auth.check('delivery')">-->

    <!--<li class="nav-item">-->
    <!--<router-link class="nav-link" :to="{name:'dashboard'}">Dashboard-->
    <!--</router-link>-->
    <!--</li>-->

    <!--&lt;!&ndash;<li class="nav-item">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-link class="nav-link" :to="{name:'deliveries.new'}">Orders&ndash;&gt;-->
    <!--&lt;!&ndash;</router-link>&ndash;&gt;-->
    <!--&lt;!&ndash;</li>&ndash;&gt;-->

    <!--</ul>-->

    <!--<nav class="navbar navbar-light mx-auto bg-transparent w-75">-->
    <!--<search></search>-->
    <!--</nav>-->

    <!--&lt;!&ndash;<notifications></notifications>&ndash;&gt;-->


    <!--<ul class="navbar-nav mr-auto" v-if="!$auth.check()">-->

    <!--<li class="nav-item" v-for="(route,index) in guest" v-bind:key="index">-->
    <!--<router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>-->
    <!--</li>-->
    <!--<li class="nav-item">-->
    <!--<router-link :to="{name:'cart.preview'}">-->
    <!--<img src="../../assets/icons/cart_icon.png" style="width: 32px;margin-top:10px;" alt="">-->
    <!--<span class="text-light"-->
    <!--style="margin-left:-16px; font-weight: bolder;">{{$store.getters.cart.list.length}}</span>-->
    <!--</router-link>-->
    <!--</li>-->
    <!--</ul>-->

    <!--<ul class="navbar-nav right dropdown">-->
    <!--<li class="nav-item mr-5" v-if="$auth.check('customer')">-->
    <!--<router-link :to="{name:'cart.preview'}">-->
    <!--<img src="../../assets/icons/cart_icon.png" style="width: 32px;margin-top:10px;" alt="">-->
    <!--<span class="text-light"-->
    <!--style="margin-left:-16px; font-weight: bolder;">{{$store.getters.cart.list.length}}</span>-->
    <!--</router-link>-->
    <!--</li>-->
    <!--<li class="nav-item dropdown" v-if="$auth.check()">-->
    <!--<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"-->
    <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
    <!--{{$auth.user().full_name}}-->
    <!--</a>-->
    <!--<div class="dropdown-menu" aria-labelledby="navbarDropdown">-->

    <!--<router-link :to="{name:'profile'}" class="dropdown-item">Profile</router-link>-->
    <!--<div class="dropdown-divider"></div>-->
    <!--<a class="dropdown-item" href="#" @click.prevent="$auth.logout()">Logout</a>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ul>-->

    <!--<ul class="navbar-nav mr-auto">-->
    <!--<li class="nav-item visible-link">-->
    <!--<router-link :to="{name:'customer.request'}" class="nav-link">-->
    <!--Become a member-->
    <!--</router-link>-->
    <!--</li>-->
    <!--<li class="nav-item visible-link">-->
    <!--<router-link :to="{name:'customer.request'}" class="nav-link">-->
    <!--See Delivery Options in Your Area-->
    <!--</router-link>-->
    <!--</li>-->
    <!--<li class="nav-item visible-link">-->
    <!--<router-link :to="{name:'customer.login'}" class="nav-link">-->
    <!--Order Online & Pickup In-Store-->
    <!--</router-link>-->
    <!--</li>-->
    <!--<li class="nav-item visible-link">-->
    <!--<router-link :to="{name:'login'}" class="nav-link">-->
    <!--Login Yo Your Account-->
    <!--</router-link>-->
    <!--</li>-->
    <!--</ul>-->

    <!--</div>-->
    <!--</div>-->
    <!--&lt;!&ndash;<div class="container-fluid bg-dark">&ndash;&gt;-->
    <!--&lt;!&ndash;<div class="collapse navbar-collapse" id="navbarSupportedContent1">&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--</nav>-->
</template>
<script>
    import {Paths} from '@/paths';
    //    import Search from '@/views/components/Search';
    //    import Notifications from '@/views/layouts/Notifications';
    export default{
        data(){
            return {
                category: null,
                guest: [
//                    {name: 'Products', path: 'shop'},
                    {name: 'Register', path: 'customer.request'},
                    {name: 'Login', path: 'login'}
                ],
                admin: [
                    {name: 'Dashboard', path: 'dashboard'},

                ],
                dropdown: [],
                salesman: [
                    {name: 'Dashboard', path: 'dashboard'},
                ],
                customer: [
                    {name: 'Orders', path: 'customer.orders'},
                ],
                warehouse: [
                    {name: 'Dashboard', 'path': 'dashboard'},
                ]
            }
        },
//        components: {Search},
        methods: {
            allowShopping(){
                if (!this.$auth.check()) {
                    return this.$store.commit('setAllowShopping', false);
                }
                let user = this.$auth.user();
                if (user.role == 'customer' && (user.customer.registration == null || user.customer.registration.status == 3)) {
                    return this.$store.commit('setAllowShopping', true);
                }
                return false;
            }
        },

        mounted(){
            let app = this;
            this.axios.post(Paths.data.fetch).then((response) => {
                app.$store.commit('setCategories', response.data.categories);
                app.$store.commit('setUnits', response.data.units);
                app.$store.commit('setSubCategories', response.data.sub_categories);
                app.$store.commit('setBrands', response.data.brands);
            }).catch(() => {
            });

            app.$store.commit('setCurrentDate');

//            console.log(JSON.stringify(this.$store.getters.cart.list));


//            this.allowShopping();


        }
    }
</script>
<style>
    @media screen and (max-width: 991px) {
        .remove-nav {
            display: none;
        }
    }

    @media screen and (min-width: 991px) {
        .visible-link {
            display: none;
        }
    }

    .navbar-custom .dropdown-menu.notify-drop {
        min-width: 280px;
        background-color: #fff;
        min-height: 360px;
        max-height: 360px;
    }

    .navbar-custom .dropdown-menu.notify-drop .notify-drop-title {
        border-bottom: 1px solid #e2e2e2;
        padding: 5px 15px 10px 15px;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content {
        min-height: 280px;
        max-height: 280px;
        overflow-y: scroll;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content::-webkit-scrollbar-track {
        background-color: #F5F5F5;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content::-webkit-scrollbar {
        width: 8px;
        background-color: #F5F5F5;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content::-webkit-scrollbar-thumb {
        background-color: #ccc;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li {
        border-bottom: 1px solid #e2e2e2;
        padding: 10px 0px 5px 0px;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li:nth-child(2n+0) {
        background-color: #fafafa;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li:after {
        content: "";
        clear: both;
        display: block;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li:hover {
        background-color: #fcfcfc;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li:last-child {
        border-bottom: none;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li .notify-img {
        float: left;
        display: inline-block;
        width: 45px;
        height: 45px;
        margin: 0px 0px 8px 0px;
    }

    .navbar-custom .dropdown-menu.notify-drop .allRead {
        margin-right: 7px;
    }

    .navbar-custom .dropdown-menu.notify-drop .rIcon {
        float: right;
        color: #999;
    }

    .navbar-custom .dropdown-menu.notify-drop .rIcon:hover {
        color: #333;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li a {
        font-size: 12px;
        font-weight: normal;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li {
        font-weight: bold;
        font-size: 11px;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li hr {
        margin: 5px 0;
        width: 70%;
        border-color: #e2e2e2;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content .pd-l0 {
        padding-left: 0;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li p {
        font-size: 11px;
        color: #666;
        font-weight: normal;
        margin: 3px 0;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li p.time {
        font-size: 10px;
        font-weight: 600;
        top: -6px;
        margin: 8px 0px 0px 0px;
        padding: 0px 3px;
        border: 1px solid #e2e2e2;
        position: relative;
        background-image: linear-gradient(#fff, #f2f2f2);
        display: inline-block;
        border-radius: 2px;
        color: #B97745;
    }

    .navbar-custom .dropdown-menu.notify-drop .drop-content > li p.time:hover {
        background-image: linear-gradient(#fff, #fff);
    }

    .navbar-custom .dropdown-menu.notify-drop .notify-drop-footer {
        border-top: 1px solid #e2e2e2;
        bottom: 0;
        position: relative;
        padding: 8px 15px;
    }

    .navbar-custom .dropdown-menu.notify-drop .notify-drop-footer a {
        color: #777;
        text-decoration: none;
    }

    .navbar-default .dropdown-menu.notify-drop .notify-drop-footer a:hover {
        color: #333;
    }
</style>