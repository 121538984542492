<template>
    <div>
        <div class="about-section-box">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 text-center">
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 op-bg p-2 rounded text-black">
                        <div class="inner-column">
                            <h1 class="text-black">Who We Are</h1>
                            <p class="text-black" style="float: right;">
                                US Halal Foods, headquartered in Minneapolis, Minnesota has emerged as one of the
                                fastest growing ethnic distributors in the state. At US Halal Foods, we strive to bring
                                Halal products from reliable sources around the world. We are dedicated to providing the
                                freshest top-quality Halal poultry, meat, and products in the market.  We also
                                distribute a full line of food products including produce and a wide variety of non-food
                                products to both independent and chain restaurant customers as well as serve other locations
                                such as healthcare facilities, educational facilities, community centers, childcare centers,
                                adult day care centers, nonprofit organizations and more. </p>


                            <router-link :to="{name:'who_we_are'}" class="btn btn-lg btn-circle btn-outline-new-white">
                                Read More
                            </router-link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-section-box1">
            <div class="container">
                <div class="row">

                    <div class="col-lg-6 col-md-6 col-sm-12 text-center op-bg p-2">
                        <div class="inner-column">
                            <h1 class="text-black">Who We Serve</h1>
                            <h4 class="text-black">It's not a Mission, it is a Passion.</h4>
                            <p class="text-black" style="float: right;">
                                US Halal Foods has the capabilities to partner with restaurants, big and small, as well as organizations like schools, universities, healthcare facilities, hotels, wholesalers and pretty much anywhere else that serves food!  Every product, every delivery, and every decision we make is inspired by helping your business thrive.</p>
                            <router-link :to="{name:'who_we_serve'}"
                                         class="btn btn-lg btn-circle btn-outline-new-white">
                                Read More
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="about-section-box2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 text-center">
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 p-2 op-bg">
                        <div class="inner-column">
                            <h1 class="text-black">What Can US Halal Foods Inc Offer Your Business?</h1>
                            <h4 class="text-black">Our Products</h4>
                            <p class="text-black" style="float: right;">
                                Have confidence in what you serve. From fresh produce and custom proteins, to kitchen staples and supplies, our focus is on service, quality, price and assortment. We guarantee the quality of everything our trucks deliver and will work with you to get the right products for your operation.
                            </p>

                            <router-link :to="{name:'offers_for_business'}"
                                         class="btn btn-lg btn-circle btn-outline-new-white">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="about-section-box3">-->
            <!--<div class="container">-->
                <!--<div class="row">-->
                    <!--<div class="col-lg-6 col-md-6 col-sm-12 text-center">-->
                        <!--<div class="inner-column">-->
                            <!--<h1>Welcome To <span>US Halal Food</span></h1>-->
                            <!--<h4>Little Story</h4>-->
                            <!--<p>-->
                                <!--Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque auctor suscipit feugiat. Ut at pellentesque ante, sed convallis arcu. Nullam facilisis, eros in eleifend luctus, odio ante sodales augue, eget lacinia lectus erat et sem. </p>-->
                            <!--<p>-->
                                <!--Sed semper orci sit amet porta placerat. Etiam quis finibus eros. Sed aliquam metus lorem, a pellentesque tellus pretium a. Nulla placerat elit in justo vestibulum, et maximus sem pulvinar.</p>-->
                            <!--<a class="btn btn-lg btn-circle btn-outline-new-white float-right" href="#">Read More</a>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="col-lg-6 col-md-6 col-sm-12">-->
                        <!--&lt;!&ndash;<img src="images/about-img.jpg" alt="" class="img-fluid">&ndash;&gt;-->
                    <!--</div>-->


                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    </div>
</template>


<script>
    export default{
        data()
        {
            return {
                who_we_are_detail: false,
            }
        },
        methods: {}
    }
</script>