<template>
    <div id="slides" class="cover-slides">
        <ul class="slides-container">
            <li class="text-left">
                <img src="../../assets/image/slider-01.jpg" alt="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="m-b-20"><strong>Welcome To <br> US Halal Foods </strong></h1>
                            <p class="m-b-40">Your #1 Trusted Halal Food Supplier!</p>
                            <!--<p><a class="btn btn-lg btn-circle btn-outline-new-white" href="#">More Details</a></p>-->
                        </div>
                    </div>
                </div>
            </li>
            <li class="text-left">
                <img src="../../assets/image/slider-02.jpg" alt="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="m-b-20"><strong>Welcome To <br> US Halal Foods</strong></h1>
                            <p class="m-b-40">Your #1 Trusted Halal Food Supplier!</p>
                            <!--<p><a class="btn btn-lg btn-circle btn-outline-new-white" href="#">More Details</a></p>-->
                        </div>
                    </div>
                </div>
            </li>
            <li class="text-left">
                <img src="../../assets/image/slider-03.jpg" alt="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="m-b-20"><strong>Welcome To <br> US Halal Foods</strong></h1>
                            <p class="m-b-40">Your #1 Trusted Halal Food Supplier!</p>
                            <!--<p><a class="btn btn-lg btn-circle btn-outline-new-white" href="#">More Details</a></p>-->
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="slides-navigation">
            <a href="#" class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
            <a href="#" class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
        </div>
    </div>
</template>
<script>
    export default{}
</script>