<template>
    <div id="app" v-if="$auth.ready()">

        <Menu></Menu>

        <div id="main-container" class="">
            <transition name="fade">
                <router-view/>
            </transition>
        </div>
        <!--<Contact></Contact>-->
    </div>
</template>
<script>
    import {Bus} from '@/main';
    import Menu from './views/layouts/Menu.vue';
    //    import DarkMenu from './views/layouts/DarkMenu.vue';
    //    import Contact from './views/index/ContactUs.vue';
    export default{
        components: {
            Menu,
//            SlideShow
        },
        data(){
            return {
                ready: false,
            }
        },
        mounted(){
            let base = this.axios.defaults.baseURL;
            // remove api from base
            base = base.substr(0, base.length - 3);
            this.$store.commit('setRootPath', base);

            // load product detail page on suggestion
            let app = this;
            Bus.$on('search-product', function (data) {
                if (data.product.length) {
                    app.$router.push({'name': 'shop', params: {category: data.category_id, query: data.product}});
                    return;
                }
                if (data.category_id == 0) {
                    app.$router.push({'name': 'shop'});
                    return;
                }
                app.$router.push({'name': 'shop', params: {category: data.category_id}});


            });
            this.$store.dispatch('loadCart');
        }

    }
</script>
<style>
    #main-container {
        padding-top:150px;
    }

    #main-container > .top-margin {
        position: absolute;
        float: left;
        width: 100%;
        /*height: 100%;*/
        overflow-x: hidden;
        /*margin-bottom: 200px !important;*/

        /*padding-bottom: 200px !important; !*top: 85px;*!*/
    }

    .text-black {
        color: #1b1e21 !important;
    }

    select {

        /* styling */
        background-color: white;
        border: thin solid blue;
        border-radius: 4px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;

        /* reset */

        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    #main-container > div {
        /*margin-top: 8rem;*/
        /*min-height: 85%;*/
        height: auto;
    }

    select.minimal {
        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px,
        5px 5px,
        1px 1.5em;
        background-repeat: no-repeat;
    }

    select.minimal:focus {
        background-image: linear-gradient(45deg, green 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, green 50%),
        linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px,
        5px 5px,
        1px 1.5em;
        background-repeat: no-repeat;
        border-color: green;
        outline: 0;
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    .opacity {
        opacity: .3;
    }

</style>