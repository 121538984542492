<template>
    <div class="row" v-if="show">
        <div class="col-12">
            <div class="alert alert-success">{{message}}</div>
        </div>
    </div>

</template>
<script>
    export default{
        props: ['show', 'message']
    }
</script>