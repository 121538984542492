import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vue2Filters from 'vue2-filters';
import vSelect from 'vue-select'




// import VueRouter from "vue-router";
import store from "./store";
import axios from "axios";
import VueAuth from "@websanova/vue-auth";
import VueAxios from "vue-axios";
import auth from "./auth";
import loader from "vue-spinner/src/PulseLoader.vue";
import SuccessAlert from "@/views/components/SuccessAlert";
import _ from "lodash";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faBars,
    faInfo,
    faSearch,
    faStar,
    faToggleOff,
    faToggleOn,
    faTrash,
    faUserEdit,
    faMap,
    faPhone,
    faEnvelope,
    faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";


library.add(faFilePdf,faUserEdit, faInfo, faToggleOn, faToggleOff, faBars, faStar, faTrash, faSearch,faMap,faPhone,faEnvelope);

window.axios = axios;
// window.Paths = Paths;
Vue.config.productionTip = false
// Vue.prototype.$http = window.axios
Vue.router = router;

Vue.use(VueAuth, auth);
Vue.use(VueAxios, axios);
Vue.use(Vue2Filters);

Object.defineProperty(Vue.prototype, '_', {value: _});
Vue.component('pulse-loader', loader);
Vue.component('success-alert', SuccessAlert);
Vue.component('icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);

// Vue.use(onlyInt);
// Vue.use(onlyFloat);
import 'vue-select/dist/vue-select.css';
require('vue2-animate/dist/vue2-animate.min.css');

export const Bus = new Vue();
new Vue({
    router,
    store,
    // mode: 'history',
    render: h => h(App)
}).$mount('#app')
