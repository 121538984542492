import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        categories: {},
        sub_categories: {},
        brands: {},
        units: {},
        root_path: '',
        current_date: '12/28/2019',
        cart: {
            order_id: 0,
            items: 0,
            price: 0,
            list: [],
            same_as_delivery: false,
            delivery_address: null,
            billing_address: null,
            payment_method: 0,
        },
        allow_shopping: false,
    },
    mutations: {
        setAllowShopping: (state, data) => {
            state.allow_shopping = data;
        },
        setBrands: (state, data) => {
            state.brands = data;
        },
        setCategories: (state, data) => {
            state.categories = data;
        },
        setSubCategories: (state, data) => {
            state.sub_categories = data;
        },
        addSubCategory: (state, data) => {
            Vue.set(state.sub_categories, data.id, data);
        },
        addCategory: (state, data) => {
            state.categories[data.id] = data;
        },
        addUnit: (state, data) => {
            state.units[data.id] = data;
        },
        setUnits: (state, data) => {
            state.units = data;
        },
        setRootPath: (state, data) => {
            state.root_path = data;
        },
        loadCart: (state, data) => {
            state.cart = data;
            localStorage.cart = JSON.stringify(state.cart);
        },
        addQuantityToCart: (state, data) => {
            let index = window._.findIndex(state.cart.list, {'item_id': data.id});
            state.cart.list[index].quantity = data.quantity;

        },
        saveCart: (state) => {
            localStorage.cart = JSON.stringify(state.cart);
        },

        setPaymentMethodInCart: (state, data) => {
            state.cart.payment_method = data;
        },
        setOrderId: (state, data) => {
            state.cart.order_id = data;

        },
        refreshCart: (state) => {
            state.cart.items = 0;
            state.cart.price = 0;
            state.cart.list.forEach(function (item) {
                state.cart.items += item.quantity;
                state.cart.price += (item.quantity * item.price);
            })
        },
        removeFromCart: (state, data) => {
            window._.remove(state.cart.list, {item_id: data.id});
        },
        setCart: (state, data) => {
            let quantity = parseInt(data.quantity);
            let price = parseFloat(data.price);
            state.cart.items += quantity;
            state.cart.price += quantity * price;

            for (let i = 0; i < state.cart.list.length; i++) {
                if (state.cart.list[i].item_id == data.id) {
                    state.cart.list[i].quantity += quantity;
                    return;
                }
            }
            state.cart.list.push({
                'item_id': data.id,
                'name': data.name,
                'price': price,
                quantity: quantity,
                image: data.image,
                'max': parseInt(data.max)
            })

            localStorage.cart = JSON.stringify(state.cart);
        },
        setDeliveryAddressInCart: (state, data) => {
            state.cart.delivery_address = data;
            localStorage.cart = JSON.stringify(state.cart);

        },
        setBillingAddressInCart: (state, data) => {
            state.cart.billing_address = data;
            localStorage.cart = JSON.stringify(state.cart);

        },
        setSameAsDeliveryInCart: (state, data) => {
            state.cart.same_as_delivery = data;
            if (data) {
                state.cart.billing_address = null;
            }
            localStorage.cart = JSON.stringify(state.cart);
        },
        clearCart: (state) => {
            state.cart.items = 0;
            state.cart.price = 0;
            state.cart.list = [];
            localStorage.cart = JSON.stringify(state.cart);
        }, setCurrentDate: (state) => {

            let today = new Date();
            let dd = today.getDate();

            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();
            // if (dd < 10) {
            //     dd = '0' + dd;
            // }
            //
            // if (mm < 10) {
            //     mm = '0' + mm;
            // }

            state.current_date = mm + '/' + dd + '/' + yyyy;

        },
    },
    getters: {
        categories: state => {
            return state.categories;
        },
        units: state => {
            return state.units;
        },
        brands: state => {
            return state.brands;
        },
        rootPath: state => {
            return state.root_path;
        },
        cart: state => {
            return state.cart;
        },
        allow_shopping: state => {
            return state.allow_shopping;
        },
        sub_categories: state => {
            return state.sub_categories;
        },
        current_date: state => {
            return state.current_date;
        }
    }
    ,
    actions: {
        loadCart(context)
        {
            if (localStorage.cart) {

                context.commit('loadCart', JSON.parse(localStorage.cart));

            }
        }
        ,
        removeCartItem(context, payload)
        {
            let cart = context.getters.cart;
            for (let i = 0; i < cart.list.length; i++) {
                if (cart.list[i].id == payload) {
                    cart.price -= cart.list[i].quantity * cart.list[i].price;
                    cart.items -= cart.list[i].quantity;
                    cart.list.splice(i, 1);
                }
            }

            context.commit('loadCart', cart);
        }
    }
    ,
    modules: {}
})
